import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { mentionize, urlize } from 'mk/bazaar/common/filters';
import { hashize } from 'mk/bazaar/common/filters/hashize';
import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import { slugify } from 'mk/common/utils';
import { BASE_URL } from 'mk/settings';
import { blogsUrl } from 'mk2/apps/blogs/urls';
import { sortPhotoVersions } from 'mk2/apps/forum/utils';
import { EshopProductWidgetSlot } from 'mk2/apps/groups/containers/EshopProducts/EshopProductWidget';
import { eshopWithChannelUrl } from 'mk2/apps/home/urls';
import type { StrollerEntity } from 'mk2/apps/strollers/schemas';
import { strollersDetailUrl, strollersListUrl } from 'mk2/apps/strollers/urls';
import { BrandLink, DetailLink } from 'mk2/apps/strollers/utils';
import type { ManualItem } from 'mk2/apps/wiki/components/ReviewWhereToBuyList';
import WikiLink from 'mk2/apps/wiki/components/WikiLink';
import { WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { wikiArticleUrl } from 'mk2/apps/wiki/urls';
import {
    constructGiphy,
    constructIframe,
    constructImage,
    constructInstagram,
    constructVimeo,
    constructYoutube,
    linkProcessor,
    LinkProcessorOpts,
    RenderSnippetAs,
} from 'mk2/apps/wiki/utils';
import { Link } from 'mk2/components/Link';
import StrollerBoxWidget from 'mk2/components/StrollerBox/StrollerBoxWidget';
import { PregnancyCalculatorStepTwoAction } from 'mk2/containers/PregnancyCalculator/PregnancyCalculator';
import {
    crHashToArticleSlug,
    getAllDescendantsOfAType,
    getAttributesDict,
    removeQuotes,
    REVIEW_ARTICLES_HASHTAG_PREFIX,
} from 'mk2/helpers/article_ast/utils';
import { cacheLast } from 'mk2/helpers/cache';
import { url, GetParams, SLUG_RE } from 'mk2/helpers/urls';
import Loadable from 'mk2/helpers/Loadable';
import {
    AST,
    ASTAdditionalDataFromServer,
    ASTInstagramData,
    ASTLinkedObjectsData,
    ASTLinkedObjectType,
    BlogPostEntity,
    EshopProductEntity,
    PhotoEntity,
    WikiArticleEntity,
    WikiArticleSchema,
} from 'mk2/schemas';
import * as path2regex from 'path-to-regexp';
import { parse } from 'query-string';
import React from 'react';
import styles from './ArticleStyle.mscss';

const ChartWidget = Loadable({
    loader: () =>
        import('mk2/apps/strollers/containers/Charts/ChartWidget' /* webpackChunkName: "strollers.ChartWidget" */),
    modules: ['mk2/apps/strollers/containers/Charts/ChartWidget'],
    webpack: () => [require.resolveWeak('mk2/apps/strollers/containers/Charts/ChartWidget')],
});

const VariousPreviewsWidget = Loadable({
    loader: () => import('mk2/apps/wiki/components/VariousPreviewsWidget' /* webpackChunkName: "wiki.VariousPreviewsWidget" */),
    modules: ['mk2/apps/wiki/components/VariousPreviewsWidget'],
    webpack: () => [ require.resolveWeak('mk2/apps/wiki/components/VariousPreviewsWidget') ],
});

const ProductBoxWidget = Loadable({
    loader: () => import('mk2/components/ProductBoxWidget' /* webpackChunkName: "components.ProductBoxWidget" */),
    modules: ['mk2/components/ProductBoxWidget'],
    webpack: () => [ require.resolveWeak('mk2/components/ProductBoxWidget') ],
});

const ReviewTestings = Loadable({
    loader: () => import('mk2/apps/wiki/components/ReviewTestings' /* webpackChunkName: "wiki.ReviewTestings" */),
    modules: ['mk2/apps/wiki/components/ReviewTestings'],
    webpack: () => [ require.resolveWeak('mk2/apps/wiki/components/ReviewTestings') ],
});

const PricemaniaWidget = Loadable({
    loader: () => import('mk2/apps/wiki/components/PricemaniaWidget' /* webpackChunkName: "wiki.PricemaniaWidget" */),
    modules: ['mk2/apps/wiki/components/PricemaniaWidget'],
    webpack: () => [ require.resolveWeak('mk2/apps/wiki/components/PricemaniaWidget') ],
});

const ReviewWhereToBuyList = Loadable({
    loader: () => import('mk2/apps/wiki/components/ReviewWhereToBuyList' /* webpackChunkName: "wiki.ReviewWhereToBuyList" */).then((mod) => mod.ReviewWhereToBuyList),
    modules: ['mk2/apps/wiki/components/ReviewWhereToBuyList'],
    webpack: () => [ require.resolveWeak('mk2/apps/wiki/components/ReviewWhereToBuyList') ],
});

const PregnancyNewsletter = Loadable({
    loader: () => import('mk2/containers/PregnancyNewsletter/PregnancyNewsletter' /* webpackChunkName: "components.PregnancyNewsletter" */),
    modules: ['mk2/containers/PregnancyNewsletter/PregnancyNewsletter'],
    webpack: () => [ require.resolveWeak('mk2/containers/PregnancyNewsletter/PregnancyNewsletter') ],
});

const PregnancyCalculator = Loadable({
    loader: () => import('mk2/containers/PregnancyCalculator/PregnancyCalculator' /* webpackChunkName: "components.PregnancyCalculator" */),
    modules: ['mk2/containers/PregnancyCalculator/PregnancyCalculator'],
    webpack: () => [ require.resolveWeak('mk2/containers/PregnancyCalculator/PregnancyCalculator') ],
});

const EshopProductWidget = Loadable({
    loader: () => import('mk2/apps/groups/containers/EshopProducts/EshopProductWidget' /* webpackChunkName: "groups.EshopProductWidget" */),
    modules: ['mk2/apps/groups/containers/EshopProducts/EshopProductWidget'],
    webpack: () => [ require.resolveWeak('mk2/apps/groups/containers/EshopProducts/EshopProductWidget') ],
});

const cacheMarketManualItems = cacheLast(true);

const RE_EXTRACT_ARTICLE_SLUG = `^(?:\\/${SLUG_RE})?(?:\\/w)?\\/(${SLUG_RE})\\/(?:[?#].+)?$`;

const BOOLEAN_ATTRIBUTE__TRUE = 'BOOLTRUE';

const NON_P_ELEMENTS = [
    'table', 'numbered_list', 'unnumbered_list', 'note', 'hr', 'h2', 'h3', 'h4', 'youtube',
    'instagram', 'vimeo', 'giphy', 'testing', 'strollers_chart', 'iframe', 'pricemania',
    'market', 'reviews', 'previews', 'code', 'product_box', 'stroller_box', 'pregnancy_newsletter', 'pregnancy_calculator', 'note',
    'blue_note', 'eshop',
];

const PROD_BASE_URL = BASE_URL.replace(/www-[a-z]{3,10}.modrykonik/, 'www.modrykonik');

type ReactChild = React.ReactChild;

interface SpecialFlag {
    key: string;
    style?: any;
}

const NO_SPECIAL_FLAG: SpecialFlag = {
    key: null,
    style: null,
};

const ARTICLE_RESOURCES_FLAG: SpecialFlag = {
    key: 'article-resources',
    style: styles.ArticleStyle__articleResources,
};

export enum RenderLinkedObjectsAs {
    VARIATIONS_OF_CURRENT_SYNTAX,
    PLAIN_URL_LINK,
    PLAIN_URL_LINK_OR_BODY,
}

export enum ShowPhotosAndVideos {
    /**
     * All photos mentiones in the body (may be the set or subset of ArticleEntity.photos).
     */
    ALL,
    /**
     * The same as ALL except photos with label `cover`.
     */
    EXCEPT_COVER,
    /**
     * Do not show any photos of the article
     */
    NONE,
}

export interface StoreEventParamsOfSourceContent {
    article_slug?: string;
    category_slug?: string;
    article_id?: number;
    post_id?: number;
    category_id?: number;
}

export interface ExtraEntitiesData {
    wikiArticleArray: WikiArticleEntity[];
    strollerArray: StrollerEntity[];
    blogPostArray: BlogPostEntity[];
    eshopProductsOfEshopNodes?: Record<string, EshopProductEntity[]>;
}

export interface FilterAdditionalData {
    _eshopIndex: number[];
    countTopicContributors: {[topicSlug: string]: number};
    linkedObjects: ASTLinkedObjectsData;
    instagramData: ASTInstagramData;
    productBoxes: ASTAdditionalDataFromServer['productBoxes'];
    strollerBoxes: ASTAdditionalDataFromServer['strollerBoxes'];
    extraEntities: ExtraEntitiesData;
    renderForEditor: boolean;
    photos: PhotoEntity[];
    sourceApp: string;
    sourceLocationPath: string;
    allowSubstitutingGetParams: GetParams; // current location's get params
    customStyles: {
        photo?: any;
        instagram?: any;
        header2?: any;
        header3?: any;
        header4?: any;
        orderedlist?: any;
        unorderedlist?: any;
        paragraph?: any;
        strollersChart?: string;
        articleNote?: string;
        blueNote?: string;
        youtube?: string;
    };
    showPhotosAndVideos?: ShowPhotosAndVideos;
    hasResponsiveImages: boolean; // if true then use ImgResponsive; else use Img
    forceEagerImages: boolean; // if true then set prop lazy (of Img/ImgResponsive) to false; else use default
    escapeMarkdownLikePartsOfPlainText: boolean;
    renderContributorsCount: boolean;
    renderSnippetObjectsAs: RenderSnippetAs;
    renderLinkedObjectsAs: RenderLinkedObjectsAs;
    renderSpecialWikiLinks: boolean;
    renderLinksWithNofollow: boolean;
    renderLinksOfUntrustworthy: string;
    renderLinks: boolean;
    rootDiv: boolean;
    anchorHeadings: boolean;
    chevronLists: boolean;
    isMobile: boolean;
    storeEventParamsOfSourceContent: StoreEventParamsOfSourceContent;
    suggestedItems: React.ReactChild[];

    selectPhotoVersionFn(photo: PhotoEntity): string;
    hashizeFn(bodyChunk: TextLike): React.ReactChild[];
    faFn(faName: string): React.ReactChild;
}

export const defaultFilterAdditionalData: FilterAdditionalData = {
    _eshopIndex: [0],
    countTopicContributors: null,
    linkedObjects: null,
    instagramData: null,
    productBoxes: null,
    strollerBoxes: null,
    extraEntities: {
        wikiArticleArray: null,
        strollerArray: null,
        blogPostArray: null,
    },
    renderForEditor: false,
    photos: [],
    sourceApp: null,
    sourceLocationPath: null,
    allowSubstitutingGetParams: null,
    customStyles: {
        photo: null,
        instagram: null,
    },
    showPhotosAndVideos: ShowPhotosAndVideos.ALL,
    hasResponsiveImages: true,
    forceEagerImages: false,
    escapeMarkdownLikePartsOfPlainText: false,
    renderContributorsCount: false,
    renderSnippetObjectsAs: RenderSnippetAs.HTML_IFRAME,
    renderLinkedObjectsAs: RenderLinkedObjectsAs.VARIATIONS_OF_CURRENT_SYNTAX,
    renderSpecialWikiLinks: true,
    renderLinksWithNofollow: true,
    renderLinksOfUntrustworthy: null, // url forumUntrustworthyUserFeedUrl
    renderLinks: true, // if false, render just label with no surrounding <a>...</a>
    rootDiv: true,
    anchorHeadings: false,
    chevronLists: false,
    isMobile: true,
    storeEventParamsOfSourceContent: null,
    suggestedItems: [],

    selectPhotoVersionFn: (photo) => sortPhotoVersions(photo.versions)[0], // largest

    hashizeFn: (bodyChunk) => hashize(
        bodyChunk,
        (hash) => {

            // a hashize function for #cr_xyz creating link to the reviews centre (Centrum recenzii)
            if (hash.startsWith(REVIEW_ARTICLES_HASHTAG_PREFIX)) {
                const articleSlugFromHash = crHashToArticleSlug(hash);
                if (articleSlugFromHash) {
                    return url(wikiArticleUrl, {
                        categorySlug: WIKI_REVIEWS_ARTICLE_SLUG,
                        articleSlug: articleSlugFromHash,
                    });
                }
            }

            // default hashize function with target in blogs
            return url(blogsUrl, {}, { h: hash });
        },
    ),
    faFn: null,
};

let postParagraphLineAppendix = null; // a helper to append image caption balow the image

function substituteGetParams(text: string, getParams: GetParams) {
    const reVariable = /%([a-z0-9_]+)%/g;
    let variableFound = reVariable.exec(text);
    while (variableFound) {
        const value = getParams[variableFound[1]]?.toString() || '...';
        text = text.replace(variableFound[0], value);
        variableFound = reVariable.exec(text);
    }
    return text;
}

export const astToHtml = (ast: AST, additionalData: FilterAdditionalData, specialFlag: SpecialFlag = NO_SPECIAL_FLAG): ReactChild[] => {
    let ret: ReactChild[] = [];
    let i: number = 0;

    if (!ast) {
        return ret;
    }

    if (ast.type === 'document') {
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        if (additionalData.rootDiv) {
            ret = [(<div>{ret}</div>)];
        }
        ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);

    } else if (ast.type === 'paragraph') {
        ret = [];

        let paragraphChilds: ReactChild[] = [];

        if (ast.elements) {

            // blog paragraphs contain: inline elements, br, headers, table, ...;  does not contain paragraph_line element
            // wiki paragraphs contain: only elements header / paragraph_line / paragraph_endblanks; does not contain br element

            const isBlogParagraph = ast.elements.filter(
                (item) => !['h2', 'h3', 'h4', 'paragraph_line', 'paragraph_endblanks'].includes(item.type),
            ).length > 0;

            if (isBlogParagraph) {
                for (const e of ast.elements) {
                    const allElementsCanBeAChildOfP = !NON_P_ELEMENTS.includes(e.type);
                    let endThisParagraphLineAndAppendPostParagraphLineAppendix = false;

                    if (allElementsCanBeAChildOfP) {
                        // if element can be a child of <p>, collect them to the array p and finally append <p>{p}</p> to array ret
                        paragraphChilds.push(...astToHtml(e, additionalData, specialFlag));
                        if (e.type === 'pic' && postParagraphLineAppendix) {
                            endThisParagraphLineAndAppendPostParagraphLineAppendix = true;
                        }
                    }
                    if (!allElementsCanBeAChildOfP || endThisParagraphLineAndAppendPostParagraphLineAppendix) {
                        // if element can't be a child of <p>, then append past elements as <p>{p}</p> to array ret and then append the actual non p element to array ret
                        if (paragraphChilds.length > 0) {
                            paragraphChilds = reactFilter(paragraphChilds, (s: string): React.ReactChild[] => [s]);
                            ret.push((
                                <p
                                    className={cx(
                                        additionalData.customStyles.paragraph,
                                        styles.ArticleStyle__paragraph,
                                        specialFlag.style,
                                        specialFlag.key,
                                    )}
                                >
                                    {paragraphChilds}
                                </p>
                            ));
                            paragraphChilds = [];
                        }
                        if (endThisParagraphLineAndAppendPostParagraphLineAppendix) {
                            if (postParagraphLineAppendix?.length > 0) {
                                ret.push(...postParagraphLineAppendix);
                                postParagraphLineAppendix = null;
                            }
                        } else {
                            ret.push(...astToHtml(e, additionalData, specialFlag));
                        }
                    }
                }
                if (paragraphChilds.length > 0) {
                    paragraphChilds = reactFilter(paragraphChilds, (s: string): React.ReactChild[] => [s]);
                    ret.push((
                        <p
                            className={cx(
                                additionalData.customStyles.paragraph,
                                styles.ArticleStyle__paragraph,
                                specialFlag.style,
                                specialFlag.key,
                            )}
                        >
                            {paragraphChilds}
                        </p>
                    ));
                }

                ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);

            } else {
                for (i = 0; i < ast.elements.length; i += 1) {
                    const e = ast.elements[i];
                    if (e.type === 'paragraph_line') {
                        if (e.elements) {
                            for (const ee of e.elements) {
                                const allElementsOfPLCanBeAChildOfP = !NON_P_ELEMENTS.includes(ee.type);
                                let endThisParagraphLineAndAppendPostParagraphLineAppendix = false;

                                if (allElementsOfPLCanBeAChildOfP) {
                                    // if element can be a child of <p>, collect them to the array p and finally append <p>{p}</p> to array ret
                                    paragraphChilds.push(...astToHtml(ee, additionalData, specialFlag));
                                    if (ee.type === 'pic' && postParagraphLineAppendix) {
                                        endThisParagraphLineAndAppendPostParagraphLineAppendix = true;
                                    }
                                }
                                if (!allElementsOfPLCanBeAChildOfP || endThisParagraphLineAndAppendPostParagraphLineAppendix) {
                                    // if element can't be a child of <p>, then append past elements as <p>{p}</p> to array ret and then append the actual non p element to array ret
                                    if (paragraphChilds.length > 0) {
                                        paragraphChilds = reactFilter(paragraphChilds, (s: string): React.ReactChild[] => [s]);
                                        ret.push((
                                            <p
                                                className={cx(
                                                    additionalData.customStyles.paragraph,
                                                    styles.ArticleStyle__paragraph,
                                                    specialFlag.style,
                                                    specialFlag.key,
                                                )}
                                            >
                                                {paragraphChilds}
                                            </p>
                                        ));
                                        paragraphChilds = [];
                                    }
                                    if (endThisParagraphLineAndAppendPostParagraphLineAppendix) {
                                        if (postParagraphLineAppendix?.length > 0) {
                                            ret.push(...postParagraphLineAppendix);
                                            postParagraphLineAppendix = null;
                                        }
                                    } else {
                                        ret.push(...astToHtml(ee, additionalData, specialFlag));
                                    }
                                }
                            }

                            // after each paragraph_line append <br> if conditions pass
                            if (paragraphChilds.length > 0) {
                                if (
                                    ast.elements.length - 1 > i + 1 &&
                                    ast.elements[i + 1].type === 'paragraph_line'
                                ) {
                                    paragraphChilds.push((<br />));
                                }
                            }
                        }
                    } else {
                        // append non paragraph_line element (header / paragraph_endblanks) separately

                        ret.push(...astToHtml(e, additionalData, specialFlag));
                    }
                }

                if (paragraphChilds.length > 0) {
                    paragraphChilds = reactFilter(paragraphChilds, (s: string): React.ReactChild[] => [s]);
                    ret.push((
                        <p
                            className={cx(
                                additionalData.customStyles.paragraph,
                                styles.ArticleStyle__paragraph,
                                specialFlag.style,
                                specialFlag.key,
                            )}
                        >
                            {paragraphChilds}
                        </p>
                    ));
                }

                // TODO - automatic suggestedItems pop them one by one after "some" paragraphs, will be defined
                // if (additionalData.suggestedItems?.length > 0) {
                //     if currentNode is not NON_P_ELEMENTS and nextNode is not NON_P_ELEMENTS
                //        and length of plain text of prevNode + currentNode is more than 700 chars
                //     ret.push(additionalData.suggestedItems?.pop());
                // }
            }

            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }

    } else if (ast.type === 'paragraph_endblanks') {
        ret = [];

    } else if (ast.type === 'numbered_list') {
        ret = [];
        if (ast.elements) {
            let listItemLev1;
            let listItemsLev2;
            let j = 0;
            for (i = 0; i < ast.elements.length; i += 1) {
                const e = ast.elements[i];
                if (e.type === 'numbered_list_item_lev1') {
                    listItemLev1 = astToHtml(e, additionalData, specialFlag);
                    listItemsLev2 = [];
                    j = 1;
                    while (i + j < ast.elements.length) {
                        if (ast.elements[i + j].type === 'numbered_list_item_lev2') {
                            listItemsLev2.push(
                                <li key={`${i}.${j}`}>
                                    {astToHtml(ast.elements[i + j], additionalData, specialFlag)}
                                </li>,
                            );
                            j += 1;
                        } else {
                            i = i + j - 1;
                            break;
                        }
                    }
                    if (listItemsLev2.length > 0) {
                        listItemsLev2 = (<ol key={`${i}.ol`}>{...listItemsLev2}</ol>);
                        listItemLev1.push(listItemsLev2);
                    }
                    listItemLev1 = (
                        <li key={`${i}`}>
                            {listItemLev1}
                        </li>
                    );
                    ret.push(listItemLev1);
                }
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <ol
                className={cx(
                    additionalData.customStyles.orderedlist,
                    styles.ArticleStyle__orderedlist,
                    specialFlag.style,
                    specialFlag.key,
                )}
            >
                {ret}
            </ol>
        )];

    } else if (ast.type === 'numbered_list_item_lev1') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        // wrap the elements of this node with <li></li> on the level of numbered_list (see above)

    } else if (ast.type === 'numbered_list_item_lev2') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        // wrap the elements of this node with <li>...</li> and <ol>...</ol> on the level of numbered_list (see above)

    } else if (ast.type === 'unnumbered_list') {
        ret = [];
        if (ast.elements) {
            let listItemLev1;
            let listItemsLev2;
            let j = 0;
            for (i = 0; i < ast.elements.length; i += 1) {
                const e = ast.elements[i];
                if (e.type === 'unnumbered_list_item_lev1') {
                    listItemLev1 = astToHtml(e, additionalData, specialFlag);
                    listItemsLev2 = [];
                    j = 1;
                    while (i + j < ast.elements.length) {
                        if (ast.elements[i + j].type === 'unnumbered_list_item_lev2') {
                            listItemsLev2.push(
                                <li key={`${i}.${j}`}>
                                    {astToHtml(ast.elements[i + j], additionalData, specialFlag)}
                                    {additionalData.chevronLists && (
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    )}
                                </li>,
                            );
                            j += 1;
                        } else {
                            i = i + j - 1;
                            break;
                        }
                    }
                    if (listItemsLev2.length > 0) {
                        listItemsLev2 = (<ul key={`${i}.ul`}>{...listItemsLev2}</ul>);
                        listItemLev1.push(listItemsLev2);
                    }
                    listItemLev1 = (
                        <li key={`${i}`}>
                            {listItemLev1}
                            {additionalData.chevronLists && (
                                <FontAwesomeIcon icon={faAngleRight} />
                            )}
                        </li>
                    );
                    ret.push(listItemLev1);
                }
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <ul
                className={cx(
                    additionalData.customStyles.unorderedlist,
                    styles.ArticleStyle__unorderedlist,
                    specialFlag.style,
                    specialFlag.key,
                )}
            >
                {ret}
            </ul>
        )];

    } else if (ast.type === 'unnumbered_list_item_lev1') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        // wrap the elements of this node with <li></li> on the level of unnumbered_list (see above)

    } else if (ast.type === 'unnumbered_list_item_lev2') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        // wrap the elements of this node with <li>...</li> and <ul>...</ul> on the level of unnumbered_list (see above)

    } else if (ast.type === 'table') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<table className={cx(styles.ArticleStyle__table)}>{ret}</table>)];

    } else if (ast.type === 'table_row') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<tbody><tr>{ret}</tr></tbody>)];

    } else if (ast.type === 'table_header_cell') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<th>{ret}</th>)];

    } else if (ast.type === 'table_cell') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<td>{ret}</td>)];

    } else if (ast.type === 'note') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <p
                className={cx(
                    additionalData.customStyles.articleNote,
                    styles.ArticleStyle__articleNote,
                    'article-note',
                )}
            >
                {ret}
            </p>
        )];

    } else if (ast.type === 'blue_note') {
        const attributes = getAttributesDict(ast);
        if (attributes.text) {
            ret = [(
                <div
                    className={cx(
                        additionalData.customStyles.blueNote,
                        styles.ArticleStyle__blueNote,
                        specialFlag.style,
                        specialFlag.key,
                    )}
                >
                    {removeQuotes(substituteGetParams(attributes.text, additionalData.allowSubstitutingGetParams))}
                </div>
            )];
        }

    } else if (ast.type === 'resources') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, ARTICLE_RESOURCES_FLAG));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }

    } else if (ast.type === 'h2') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <h2
                className={cx(
                    additionalData.customStyles.header2,
                    styles.ArticleStyle__header2,
                    specialFlag.style,
                    specialFlag.key,
                )}
                id={additionalData.anchorHeadings ? slugifyContent(ret) : null}
            >
                {ret}
            </h2>
        )];

    } else if (ast.type === 'h3') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <h3
                className={cx(
                    additionalData.customStyles.header3,
                    styles.ArticleStyle__header3,
                    specialFlag.style,
                    specialFlag.key,
                )}
                id={additionalData.anchorHeadings ? slugifyContent(ret) : null}
            >
                {ret}
            </h3>
        )];

    } else if (ast.type === 'h4') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <h4
                className={cx(
                    additionalData.customStyles.header4,
                    styles.ArticleStyle__header4,
                    specialFlag.style,
                    specialFlag.key,
                )}
                id={additionalData.anchorHeadings ? slugifyContent(ret) : null}
            >
                {ret}
            </h4>
        )];

    } else if (ast.type === 'bold') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<b>{ret}</b>)];

    } else if (ast.type === 'italic') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<i>{ret}</i>)];

    } else if (ast.type === 'code') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <pre className={styles.ArticleStyle__code}>
                {ret}
            </pre>
        )];

    } else if (ast.type === 'code_inline') {
        ret = [];
        if (ast.elements) {
            for (const e of ast.elements) {
                ret.push(...astToHtml(e, additionalData, specialFlag));
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(
            <code className={styles.ArticleStyle__code_inline}>
                {ret}
            </code>
        )];

    } else if (ast.type === 'link') {
        ret = linkToHtml(ast, additionalData, specialFlag);

    } else if (ast.type === 'fa') {
        if (additionalData.faFn) {
            const faElem = additionalData.faFn(ast.elements[0].text);
            if (faElem) {
                ret = [faElem];
            }
        }

    } else if (ast.type === 'pic') {
        const attributes = getAttributesDict(ast);
        const cover = attributes.hasOwnProperty('cover');
        if (
            additionalData.showPhotosAndVideos === ShowPhotosAndVideos.ALL ||
            additionalData.showPhotosAndVideos === ShowPhotosAndVideos.EXCEPT_COVER && !cover
        ) {
            const cd = removeQuotes(attributes.hasOwnProperty('code')
                ? attributes.code
                : attributes.hasOwnProperty('cd')
                    ? attributes.cd
                    : '');
            const align = removeQuotes(attributes.hasOwnProperty('align') ? attributes.align : 'center');
            const size = removeQuotes(attributes.hasOwnProperty('size') ? attributes.size : '');
            const title = removeQuotes(attributes.hasOwnProperty('title') ? attributes.title : '');
            const caption = removeQuotes(attributes.hasOwnProperty('caption') ? attributes.caption : '');
            const linkAddress = removeQuotes(attributes.hasOwnProperty('link_address') ? attributes.link_address : '');
            const isAvatar = attributes.hasOwnProperty('avatar');
            const photo = additionalData.photos.filter((p) => p.code === cd);

            if (photo.length === 0) {
                ret = [];
            } else {

                let version = '';
                if (size === '' && photo[0].versions && photo[0].versions.length > 0) {
                    version = additionalData.selectPhotoVersionFn(photo[0]);
                }

                let imgElement: ReactChild[] = [(
                    <span
                        className={cx(
                            additionalData.customStyles.photo,
                            styles.ArticleStyle__photo,
                        )}
                    >
                        {constructImage(
                            additionalData.photos,
                            cd,
                            align,
                            size,
                            version,
                            title,
                            isAvatar,
                            additionalData.hasResponsiveImages,
                            additionalData.forceEagerImages,
                        )}
                    </span>
                )];

                if (linkAddress) {
                    imgElement = reactFilter(imgElement, (s: string): React.ReactChild[] => [s]);
                    let linkOpts: LinkProcessorOpts;
                    linkOpts = {nofollow: additionalData.renderLinksWithNofollow};
                    if (specialFlag === ARTICLE_RESOURCES_FLAG) {
                        linkOpts = {nofollow: false};
                    }
                    if (linkAddress && (linkAddress.startsWith(PROD_BASE_URL) || linkAddress.startsWith('/'))) {
                        linkOpts.target = false;
                    }
                    imgElement = [linkProcessor(linkAddress, imgElement, additionalData.countTopicContributors, linkOpts)];
                }
                ret = reactFilter(imgElement, (s: string): React.ReactChild[] => [s]);

                if (caption) {
                    const picCaption = (
                        <p
                            key={'caption'}
                            className={cx(
                                additionalData.customStyles.articleNote,
                                styles.ArticleStyle__articleNote,
                                'article-note',
                            )}
                        >
                            {caption}
                        </p>
                    );
                    if (Array.isArray(postParagraphLineAppendix)) {
                        postParagraphLineAppendix.push(picCaption);
                    } else {
                        postParagraphLineAppendix = [picCaption];
                    }
                }
            }
        }
    } else if (ast.type === 'iframe') {
        const attributes = getAttributesDict(ast);
        const src = removeQuotes(attributes.hasOwnProperty('src') ? attributes.src : '');
        const desktopHeight = attributes.hasOwnProperty('desktop-height') ? parseInt(removeQuotes(attributes['desktop-height']), 10) : null;
        const mobileHeight = attributes.hasOwnProperty('mobile-height') ? parseInt(removeQuotes(attributes['mobile-height']), 10) : null;
        const iframe = [constructIframe(
            src,
            additionalData.isMobile ? mobileHeight : desktopHeight,
            additionalData.renderSnippetObjectsAs,
            styles.ArticleStyle__iframe,
        )];
        ret = reactFilter(iframe, (s: string): React.ReactChild[] => [s]);
    } else if (['youtube', 'vimeo', 'giphy', 'instagram'].includes(ast.type)) {
        const attributes = getAttributesDict(ast);
        const cover = attributes.hasOwnProperty('cover');
        if (
            additionalData.showPhotosAndVideos === ShowPhotosAndVideos.ALL ||
            additionalData.showPhotosAndVideos === ShowPhotosAndVideos.EXCEPT_COVER && !cover
        ) {
            ret = [];
            const code = removeQuotes(attributes.hasOwnProperty('code') ? attributes.code : '');
            const width = removeQuotes(attributes.hasOwnProperty('width') ? attributes.width : '');
            const height = removeQuotes(attributes.hasOwnProperty('height') ? attributes.height : '');

            if (ast.type === 'youtube') {
                const youtubeElement = constructYoutube(
                    code,
                    width && height ? parseInt(width, 10) : 560,
                    width && height ? parseInt(height, 10) : 315,
                    additionalData.renderSnippetObjectsAs,
                    cx(
                        additionalData.customStyles.youtube,
                        styles.ArticleStyle__iframe,
                    ),
                );
                ret = [youtubeElement];
            }
            if (ast.type === 'vimeo') {
                const vimeoElement = constructVimeo(
                    code,
                    width && height ? parseInt(width, 10) : 560,
                    width && height ? parseInt(height, 10) : 315,
                    additionalData.renderSnippetObjectsAs,
                    styles.ArticleStyle__iframe,
                );
                ret = [vimeoElement];
            }
            if (ast.type === 'instagram') {

                const instagramData = additionalData.instagramData && additionalData.instagramData[code]
                    ? additionalData.instagramData[code]
                    : null;

                const instagramElement: ReactChild[] = [(
                    <div className={additionalData.customStyles.instagram}>
                        {constructInstagram(
                            code,
                            height ? parseInt(height, 10) : undefined,
                            additionalData.renderSnippetObjectsAs,
                            instagramData,
                        )}
                    </div>
                )];
                ret = reactFilter(instagramElement, (s: string): React.ReactChild[] => [s]);
            }
            if (ast.type === 'giphy') {
                const giphyElement = constructGiphy(
                    code,
                    width && height ? parseInt(width, 10) : 560,
                    width && height ? parseInt(height, 10) : 315,
                    additionalData.renderSnippetObjectsAs,
                    styles.ArticleStyle__iframe,
                );
                ret = [giphyElement];
            }
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
    } else if (ast.type === 'testing') {
        ret = [];
        const testingElement: ReactChild[] = [(
            <ReviewTestings
                testingAST={ast}
                testingASTAdditionalData={additionalData}
            />
        )];
        ret = reactFilter(testingElement, (s: string): React.ReactChild[] => [s]);

    } else if (ast.type === 'strollers_chart') {
        ret = [];
        const attributes = getAttributesDict(ast);
        const title = removeQuotes(attributes.title);
        const filter = removeQuotes(attributes.filter);
        if (title && filter) {
            const component: ReactChild[] = [(
                <ChartWidget
                    className={additionalData.customStyles.strollersChart}
                    title={title}
                    filter={parse(filter)}
                />
            )];
            ret = reactFilter(component, (s: string): React.ReactChild[] => [s]);
        }
    } else if (ast.type === 'pricemania') {
        const attributes = getAttributesDict(ast);
        const id = removeQuotes(attributes.hasOwnProperty('id') ? attributes.id : '');
        const maxHeight = removeQuotes(attributes.hasOwnProperty('height') ? attributes.height : '');
        if (id) {
            const component = [(
                <PricemaniaWidget
                    id={id}
                    maxHeight={maxHeight ? parseInt(maxHeight, 10) : undefined}
                />
            )];
            ret = reactFilter(component, (s: string): React.ReactChild[] => [s]);
        }
    } else if (ast.type === 'market') {
        ret = [];
        const attributes = getAttributesDict(ast);
        const title: string = removeQuotes(attributes.title);
        const manualItemsStr = removeQuotes(attributes['manual-items']);
        const manualItems: ManualItem[] = cacheMarketManualItems(() => {
            const items = [];
            if (manualItemsStr) {
                const lines = manualItemsStr.split(/\s*\r?\n\s*/);
                for (const line of lines) {
                    const values = line.split(';');
                    if ([2, 3].includes(values.length)) {
                        items.push({
                            eshopName: values[0],
                            eshopUrl: values[1],
                            price: values.length === 3 ? values[2] : null,
                        });
                    }
                }
            }
            return items;
        }, manualItemsStr);

        if (manualItems?.length > 0) {
            const component: ReactChild[] = [(
                <ReviewWhereToBuyList
                    title={title}
                    manualItems={manualItems}
                    trackClickWithDetails={additionalData.storeEventParamsOfSourceContent}
                />
            )];
            ret = reactFilter(component, (s: string): React.ReactChild[] => [s]);
        }
    } else if (ast.type === 'reviews' || ast.type === 'previews') {
        ret = [];
        const attributes = getAttributesDict(ast);
        const title = attributes?.title ? removeQuotes(attributes.title) : '';
        const reviewsWidget: ReactChild[] = (
            additionalData.extraEntities?.wikiArticleArray ||
            additionalData.extraEntities?.strollerArray ||
            additionalData.extraEntities?.blogPostArray
        ) ? [(
            <VariousPreviewsWidget
                contentAST={ast}
                wikiArticles={additionalData.extraEntities.wikiArticleArray}
                blogPosts={additionalData.extraEntities.blogPostArray}
                strollers={additionalData.extraEntities.strollerArray}
                title={title}
            />
        )] : null;
        ret = reactFilter(reviewsWidget, (s: string): React.ReactChild[] => [s]);

    } else if (ast.type === 'product_box') {
        ret = [];
        const widget: ReactChild[] = additionalData.extraEntities?.wikiArticleArray || additionalData.extraEntities?.strollerArray ? [(
            <ProductBoxWidget
                contentAST={ast}
                wikiArticles={additionalData.extraEntities.wikiArticleArray}
                strollers={additionalData.extraEntities.strollerArray}
                data={additionalData.productBoxes}
            />
        )] : null;
        ret = reactFilter(widget, (s: string): React.ReactChild[] => [s]);
    } else if (ast.type === 'stroller_box') {
        ret = [];
        const attributes = getAttributesDict(ast);
        const strollerId = removeQuotes(attributes.hasOwnProperty('stroller_id') ? attributes.stroller_id : null);
        const strollerBoxData = strollerId ? additionalData.strollerBoxes[strollerId] : null;

        const widget: ReactChild[] = strollerBoxData ? [(
            <StrollerBoxWidget
                eshopSaleorProduct={strollerBoxData}
                widgetPosition={strollerBoxData.index}
                slot={EshopProductWidgetSlot.STROLLER_COUNSELLING}  // TODO marcel: riesit pri wiki
                pageId={0}
            />
        )] : null;
        ret = reactFilter(widget, (s: string): React.ReactChild[] => [s]);
    } else if (ast.type === 'pregnancy_newsletter') {
        const attributes = getAttributesDict(ast);

        const signupPropsFromAttributes = attributes.signupconf
            ? parse(removeQuotes(attributes.signupconf))
            : {};

        let initialPregnancyWeek: number;

        if (attributes.title) {
            signupPropsFromAttributes.title = removeQuotes(attributes.title);
        }
        if (attributes.text) {
            signupPropsFromAttributes.text = removeQuotes(attributes.text);
        }
        if (attributes.ci) {
            signupPropsFromAttributes.ci = removeQuotes(attributes.ci);
        }
        if (attributes.initial_week) {
            initialPregnancyWeek = parseInt(removeQuotes(attributes.initial_week), 10) || undefined;
            initialPregnancyWeek = (
                initialPregnancyWeek &&
                1 <= initialPregnancyWeek &&
                initialPregnancyWeek <= 42
            ) ? initialPregnancyWeek : undefined;
        }
        if (!signupPropsFromAttributes.ci || !attributes.title || !attributes.text) {
            signupPropsFromAttributes.ci = attributes.title && attributes.text ? 'var' : 'def';
        }

        const extraEventProps = (
            additionalData.sourceApp === 'wiki' &&
            additionalData.sourceLocationPath
        ) ? {
            subscribe_source: 'tehu_nl_widget_custom',
            subscribe_source_app: 'wiki',
            subscribe_source_detail: `wiki${additionalData.sourceLocationPath}`,
        } : {};


        ret.push(
            <div className={styles.ArticleStyle__pregnancyNewsletter}>
                <PregnancyNewsletter
                    formNameKey={'article'}
                    initialPregnancyWeek={initialPregnancyWeek}
                    extraEventProps={extraEventProps}
                    signupProps={signupPropsFromAttributes}
                />
            </div>,
        );
    } else if (ast.type === 'pregnancy_calculator') {
        const attributes = getAttributesDict(ast);
        let action = PregnancyCalculatorStepTwoAction.PREGNANCY_NEWSLETTER;  // default
        if (attributes.action) {
            action = removeQuotes(attributes.action) as PregnancyCalculatorStepTwoAction;
        }
        ret.push(
            <div className={styles.ArticleStyle__pregnancyCalculator}>
                <PregnancyCalculator
                    action={action}
                />
            </div>,
        );
    } else if (ast.type === 'eshop') {
        const eshopIndex = additionalData._eshopIndex[0];
        if (additionalData.extraEntities.eshopProductsOfEshopNodes) {
            const attributes = getAttributesDict(ast);
            const eshopProducts: EshopProductEntity[] = additionalData.extraEntities.eshopProductsOfEshopNodes[eshopIndex.toString()];
            const smallBool = removeQuotes(attributes.hasOwnProperty('small') ? attributes.small : '') === 'true';
            const headerBool = removeQuotes(attributes.hasOwnProperty('header') ? attributes.header : '') === 'true';
            const strollerCounselling = removeQuotes(attributes.hasOwnProperty('counsell') ? attributes.counsell : '') === 'true';

            const component = [(
                <EshopProductWidget
                    pageId={additionalData.storeEventParamsOfSourceContent?.article_id}
                    eshopProducts={eshopProducts}
                    widgetPosition={eshopIndex}
                    slot={strollerCounselling ? EshopProductWidgetSlot.STROLLER_COUNSELLING : EshopProductWidgetSlot.WIKI_ARTICLE}
                    small={smallBool}
                    header={headerBool}
                />
            )];

            ret = reactFilter(component, (s: string): React.ReactChild[] => [s]);

            additionalData._eshopIndex[0] += 1;
            if (additionalData._eshopIndex[0] >= Object.keys(additionalData.extraEntities.eshopProductsOfEshopNodes).length) {
                additionalData._eshopIndex[0] = 0;
            }
        }
    } else if (ast.type === 'plain_text') {
        ret = [];
        if (ast.text) {
            let text = ast.text;
            if (additionalData.allowSubstitutingGetParams) {
                text = substituteGetParams(text, additionalData.allowSubstitutingGetParams);
            }
            ret = [text];
        }

    } else if (ast.type === 'hashtag') {
        ret = [];
        if (ast.text) {
            ret = additionalData.hashizeFn([ast.text]);
        }
        ret = [(<span className={styles.ArticleStyle__hashtag}>{ret}</span>)];

    } else if (ast.type === 'mention') {
        ret = [];
        if (ast.text) {
            ret = mentionize(ast.text);
            ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
        }
        ret = [(<span className={styles.ArticleStyle__mention}>{ret}</span>)];

    } else if (ast.type === 'hr') {
        ret.push(<hr className={cx(styles.ArticleStyle__hr)} />);

    } else if (ast.type === 'br') {
        ret.push(<br />);

    } else if (ast.type === 'span') {
        // ignore

    } else if (ast.type === 'attribute') {
        // ignore, should not handle here

    } else {
        ret.push(<>{ast.type.toUpperCase()}</>);
    }

    ret = reactFilter(ret, (s: string): React.ReactChild[] => [s]);
    return ret;
};

export const linkToHtml = (ast: AST, additionalData: FilterAdditionalData, specialFlag: SpecialFlag = NO_SPECIAL_FLAG): ReactChild[] => {
    let ret: ReactChild[] = [];
    const plainTextNode = ast.elements.find((e) => e.type === 'plain_text');
    let plainTextText = plainTextNode ? plainTextNode.text : '';

    const linkToObjectNode = ast.elements.find((e) => e.type === 'link_to_object');
    const objType = linkToObjectNode ? linkToObjectNode.obj_type : '';
    const objId = linkToObjectNode ? linkToObjectNode.obj_id : null;
    const objQOH = linkToObjectNode ? (linkToObjectNode.obj_qoh || '') : null; // link's query/hash part
    if (
        objType && objId && (
            !Object.keys(additionalData.linkedObjects).includes(objType) ||
            !Object.keys(additionalData.linkedObjects[objType]).includes(objId.toString())
        )
    ) {
        return [];
    }

    if (objType && objId && additionalData.linkedObjects && additionalData.linkedObjects[objType]) {
        const objData = additionalData.linkedObjects[objType][objId];

        if (!additionalData.renderLinks) {
            return [plainTextText] || (objData ? [objData.url] : []);
        }

        if (objType === ASTLinkedObjectType.STROLLERS_STROLLER_DETAIL) {
            if (objData) {
                if (plainTextText) {
                    if (!additionalData.renderForEditor) {
                        // render the component with click tracker
                        const strollersDetailUrlRe = path2regex(strollersDetailUrl);
                        const parsedUrl = strollersDetailUrlRe.exec(objData.url);
                        ret = [(
                            <DetailLink
                                key={`${objType}-${objId}`}
                                slug={parsedUrl[1]}
                                visibleText={plainTextText || objData.title}
                            />
                        )];
                    } else {
                        // render simple link component
                        const strollersDetailUrlRe = path2regex(strollersDetailUrl);
                        const parsedUrl = strollersDetailUrlRe.exec(objData.url);
                        ret = [(
                            <Link
                                to={url(strollersDetailUrl, { slug: parsedUrl[1] })}
                                key={`${objType}-${objId}`}
                            >
                                {plainTextText || objData.title}
                            </Link>
                        )];
                    }
                } else {
                    ret = [(
                        <Link to={objData.url + objQOH}>
                            {objData.title || PROD_BASE_URL + objData.url + objQOH}
                        </Link>
                    )];
                }
            }
        } else if (objType === ASTLinkedObjectType.STROLLERS_STROLLER_BRAND) {
            if (objData) {
                if (plainTextText) {
                    const strollersListUrlRe = path2regex(strollersListUrl);
                    const parsedUrl = strollersListUrlRe.exec(objData.url);
                    ret = [(
                        <BrandLink
                            key={`${objType}-${objId}`}
                            slug={parsedUrl[1]}
                            visibleText={plainTextText || objData.title}
                        />
                    )];
                } else {
                    ret = [(
                        <Link to={objData.url + objQOH}>
                            {objData.title || PROD_BASE_URL + objData.url + objQOH}
                        </Link>
                    )];
                }
            }
        } else if (objType === 'eshop_collection' || objType === 'eshop_category') {
            if (objData) {
                ret = [(
                    <Link
                        to={url(`${eshopWithChannelUrl}${objData.url}`, {})}
                        key={`${objType}-${objId}`}
                    >
                        {plainTextText || objData.title}
                    </Link>
                )];
            }
        } else if (objType === ASTLinkedObjectType.WIKI_ARTICLE) {
            if (objData) {
                if (additionalData.renderSpecialWikiLinks && plainTextText) {
                    ret = [(
                        <WikiLink
                            key={`${objType}-${objId}`}
                            link={objData.url + objQOH}
                            visibleText={plainTextText || objData.title}
                            count={additionalData.renderForEditor ? 0 : (objData as any).countExperiences}
                        />
                    )];
                } else {
                    ret = [(
                        <Link to={objData.url + objQOH}>
                            {plainTextText || PROD_BASE_URL + objData.url + objQOH}
                        </Link>
                    )];
                }
            }
        } else {
            // in case:
            //      ASTLinkedObjectType.PHOTOBLOG_ARTICLE
            //      ASTLinkedObjectType.PHOTOBLOG_MESSAGE
            //      ASTLinkedObjectType.PHOTOBLOG_ALBUM
            //      ASTLinkedObjectType.FORUM_POST_DETAIL
            if (objData) {
                ret = [(
                    <Link to={objData.url}>
                        {plainTextText || PROD_BASE_URL + objData.url + objQOH}
                    </Link>
                )];
            }
        }
    } else {
        const linkAddressNode = ast.elements.find((e) => e.type === 'link_address');
        let linkAddressText = linkAddressNode ? linkAddressNode.text : '';
        const attributes = getAttributesDict(ast);
        if (attributes.hasOwnProperty('link_address')) {
            linkAddressText = removeQuotes(attributes.link_address);
        }

        let linkOpts: LinkProcessorOpts;
        linkOpts = {nofollow: additionalData.renderLinksWithNofollow};

        /* disabled cause nofollow=false is currently default for wiki articles, see apps/wiki/../ArticleBody.tsx
         * if (specialFlag === 'article-resources') {
         *     linkOpts = {nofollow: false};
         * }
         */

        if (additionalData.renderLinksOfUntrustworthy) {
            plainTextText = linkAddressText;
            linkAddressText = additionalData.renderLinksOfUntrustworthy;
        }

        if (linkAddressText && linkAddressText.startsWith(PROD_BASE_URL)) {
            linkOpts.target = false;
        }

        if (!additionalData.renderLinks) {
            return [plainTextText] || [linkAddressText];
        }

        if (linkAddressText !== '' && plainTextText !== '') {
            ret = [linkProcessor(
                linkAddressText,
                plainTextText,
                additionalData.countTopicContributors,
                linkOpts,
                specialFlag === ARTICLE_RESOURCES_FLAG && additionalData.renderContributorsCount,
            )];
        } else if (linkAddressText !== '') {
            ret = urlize(linkAddressText, specialFlag === ARTICLE_RESOURCES_FLAG ? 100 : 50, linkOpts);
        }
    }
    return ret;
};

const REMOVE_PREV_NEWLINE = '#_REMOVE_PREV_NEWLINE_#';

const slugifyContent = (nodes: React.ReactChild[]): string => {
    const firstTextNode = nodes.find((node) => typeof node === 'string');
    return firstTextNode ? 'h-' + slugify(firstTextNode as string) : null;
};

// arrays holding markdown ending/starting strings
// applicable in case of <br> to interupt the formatting and start again on the new line
// to convert '<b>abc<br>def</b>' to '**abc**\n**def**'
const markdownStackEnd = [];
const markdownStackStart = [];

export const astToMarkdown = (treeNode: AST, additionalData: FilterAdditionalData, ignoreAttribute = true): string => {
    let result = '';
    const hasType = 'type' in treeNode;
    const hasElements = 'elements' in treeNode && !!treeNode.elements && treeNode.elements.length > 0;
    const hasText = 'text' in treeNode && treeNode.text != null;

    if (hasType) {
        if (treeNode.type === '') {
            return null;

        //////////////////////////////
        // Document nodes
        } else if (treeNode.type === 'document') {
            result += ''; // beginning
            for (const subNode of treeNode.elements) {
                result += astToMarkdown(subNode, additionalData);
            }
            result = result.replace('\n' + REMOVE_PREV_NEWLINE, '').replace(REMOVE_PREV_NEWLINE, '');
            result += ''; // end

        } else if (treeNode.type === 'paragraph') {
            if (hasElements) {
                result += ''; // beginning
                let containParagraphLine = false;
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                    if (subNode.type === 'paragraph_line') {
                        containParagraphLine = true;
                    }
                }
                if (!containParagraphLine) {
                    result += '\n'; // add extra new line after a paragraph where is no paragraph line (html articles)
                }
            }
            result += '\n';

        } else if (treeNode.type === 'paragraph_line') {
            if (hasElements) {
                result += ''; // beginning
                let containComplexElement = false;
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                    if (
                        subNode.type === 'table' ||
                        subNode.type === 'numbered_list' ||
                        subNode.type === 'unnumbered_list' ||
                        subNode.type === 'note'
                    ) {
                        containComplexElement = true;
                    }
                }
                if (!containComplexElement) {
                    result += '\n';  // add extra new line after a paragraph where is no table or list
                }
            }

        } else if (treeNode.type === 'paragraph_endblanks') {
            if (hasText) {
                // render trailing useless blank lines of the paragraph
                // result += treeNode['text']

                // ignore trailing useless blank lines of the paragraph
                result += '';
            } else {
                // assert false  # missing text attribute
            }

        //////////////////////////////
        // Complex elements

        } else if (treeNode.type === 'numbered_list') {
            if (hasElements) {
                result += ''; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += ''; // end
            }

        } else if (treeNode.type === 'numbered_list_item_lev1') {
            if (hasElements) {
                result += '-# '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += '\n'; // end
            }

        } else if (treeNode.type === 'numbered_list_item_lev2') {
            if (hasElements) {
                result += '-## '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += '\n'; // end
            }

        } else if (treeNode.type === 'unnumbered_list') {
            if (hasElements) {
                result += ''; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += ''; // end
            }

        } else if (treeNode.type === 'unnumbered_list_item_lev1') {
            if (hasElements) {
                result += '- '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += '\n'; // end
            }

        } else if (treeNode.type === 'unnumbered_list_item_lev2') {
            if (hasElements) {
                result += '-- '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += '\n'; // end
            }

        } else if (treeNode.type === 'table' && hasElements) {
            result += ''; // beginning
            for (const subNode of treeNode.elements) {
                result += astToMarkdown(subNode, additionalData);
            }
            result += ''; // end

        } else if (treeNode.type === 'table_row' && hasElements) {
            const tHCells = getAllDescendantsOfAType(treeNode, 'table_header_cell', 1);
            const tDCells = getAllDescendantsOfAType(treeNode, 'table_cell', 1);
            if (tDCells.length + tHCells.length) {
                result += '|'; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += '\n'; // end
            }

        } else if (treeNode.type === 'table_header_cell') {
            result += '='; // beginning
            if (hasElements) {
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
            }
            result += '|'; // end

        } else if (treeNode.type === 'table_cell') {
            result += ''; // beginning
            if (hasElements) {
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
            }
            result += '|'; // end

        } else if (treeNode.type === 'note') {
            if (hasElements) {
                result += ':::: '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += '\n'; // end
            }

        } else if (treeNode.type === 'blue_note') {
            result += '<<blue_note'; // beginning
            if (hasElements) {
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData, false);
                }
            }
            result += '>>'; // end

        } else if (treeNode.type === 'resources') {
            if (hasElements) {
                result += '++++\n'; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
                result += REMOVE_PREV_NEWLINE + '++++\n\n'; // end
            }

        //////////////////////////////
        // Rich text nodes / leaves

        } else if (treeNode.type === 'h2') {
            if (hasElements) {
                result += '== '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
            }
            result += '\n'; // end

        } else if (treeNode.type === 'h3') {
            if (hasElements) {
                result += '=== '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
            }
            result += '\n'; // end

        } else if (treeNode.type === 'h4') {
            if (hasElements) {
                result += '==== '; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData);
                }
            }
            result += '\n'; // end

        } else if (treeNode.type === 'bold') {
            if (hasElements) {
                result += '**'; // beginning
                markdownStackEnd.push('**');
                markdownStackStart.push('**');
                if (hasElements) {
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData);
                    }
                }
                markdownStackEnd.pop();
                markdownStackStart.pop();
                result += '**'; // end
            }

        } else if (treeNode.type === 'italic') {
            if (hasElements) {
                result += '//'; // beginning
                markdownStackEnd.push('//');
                markdownStackStart.push('//');
                if (hasElements) {
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData);
                    }
                }
                markdownStackEnd.pop();
                markdownStackStart.pop();
                result += '//'; // end
            }

        } else if (treeNode.type === 'code') {
            if (hasElements) {
                result += '{{{\n'; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(
                        subNode,
                        {...additionalData, escapeMarkdownLikePartsOfPlainText: false},
                        true,
                    );
                }
                result += '}}}\n'; // end
            }

        } else if (treeNode.type === 'code_inline') {
            if (hasElements) {
                result += '{{{'; // beginning
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(
                        subNode,
                        {...additionalData, escapeMarkdownLikePartsOfPlainText: false},
                        true,
                    );
                }
                result += '}}}'; // end
            }

        } else if (treeNode.type === 'link') {
            if (hasElements) {
                let linkAddress = '';
                let linkAddressWikiWithCategory = '';
                let description = '';
                let objType: ASTLinkedObjectType = null;
                let objId: number = null;
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'attribute') {
                        const attributeName = subNode.elements.find((e) => e.type === 'attribute_name').text;
                        const attributeValue = subNode.elements.find((e) => e.type === 'attribute_value').text;
                        if (attributeName === 'link_address') {
                            linkAddress = removeQuotes(attributeValue);
                        }
                    }
                    if ('type' in subNode && subNode.type === 'link_address') {
                        linkAddress = subNode.text;
                    }
                    if ('type' in subNode && subNode.type === 'link_to_object') {
                        objType = subNode.obj_type;
                        objId = subNode.obj_id;

                        if (
                            !Object.keys(additionalData.linkedObjects).includes(objType) ||
                            !Object.keys(additionalData.linkedObjects[objType]).includes(objId.toString())
                        ) {
                            linkAddress = '';
                            objId = null;
                        } else {
                            const objUrl = additionalData.linkedObjects[objType][objId].url;
                            const objQOH = subNode.obj_qoh || '';
                            linkAddress = `${objUrl}${objQOH}`;

                            if (objType === 'wiki_article' || subNode.obj_schema_key === WikiArticleSchema.key) {
                                const reMatch = linkAddress.match(RE_EXTRACT_ARTICLE_SLUG);
                                linkAddressWikiWithCategory = linkAddress;
                                if (reMatch) {
                                    const slug = reMatch[1];
                                    // shorten link without wiki category slug
                                    linkAddress = `/${slug}/${objQOH}`;
                                }
                            }
                        }
                    }
                    if ('type' in subNode && subNode.type === 'plain_text') {
                        description = subNode.text;
                    }
                }

                // render as markdown
                // [[/article-slug/|Description]]
                // [[/article-slug/#optional-tag|Description]]
                if (additionalData.renderLinkedObjectsAs === RenderLinkedObjectsAs.VARIATIONS_OF_CURRENT_SYNTAX) {
                    if (linkAddress !== '' && description !== '') {
                        result += '[[' + linkAddress + '|' + description + ']]';
                    } else if (linkAddress !== '' && description === '') {
                        if (objType === 'strollers_app_detail') {
                            const objUrl = additionalData.linkedObjects[objType][objId].url;
                            if (objUrl) {
                                result += '[[' + objUrl + ']]';
                            } else {
                                result += '[[stroller_not_found]]';
                            }
                        } else if (objType === 'strollers_app_list') {
                            const objUrl = additionalData.linkedObjects[objType][objId].url;
                            if (objUrl) {
                                result += '[[' + objUrl + ']]';
                            } else {
                                result += '[[stroller_brand_not_found]]';
                            }
                        } else {
                            result += objId ? PROD_BASE_URL + (linkAddressWikiWithCategory || linkAddress) : linkAddress;
                        }
                    } else if (linkAddress === '' && description !== '') {
                        // assert false  # missing linkAddress
                        result += '[[' + '' + '|' + description + ']]';
                    }

                // render as plain link
                // https://www.modrykonik.sk/category-slug/article-slug/
                // https://www.modrykonik.sk/category-slug/article-slug/#optional-tag
                } else if (additionalData.renderLinkedObjectsAs === RenderLinkedObjectsAs.PLAIN_URL_LINK) {
                    result += objId ? PROD_BASE_URL + (linkAddressWikiWithCategory || linkAddress) : linkAddress;
                } else if (additionalData.renderLinkedObjectsAs === RenderLinkedObjectsAs.PLAIN_URL_LINK_OR_BODY) {
                    if (objType === ASTLinkedObjectType.STROLLERS_STROLLER_DETAIL && description !== '') {
                        result += description;
                    } else {
                        result += objId ? PROD_BASE_URL + (linkAddressWikiWithCategory || linkAddress) : linkAddress;
                    }
                } else {
                    // assert false
                }
            }

        } else if (treeNode.type === 'pic') {
            if (hasElements) {
                result += '<<pic'; // beginning
                let attributes = '';
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'attribute') {
                        attributes += astToMarkdown(subNode, additionalData, false);
                    }
                }
                if (!attributes.includes(' title="')) {
                    attributes += ' title=""';
                }
                result += attributes;
                result += '>>\n'; // end
            }

        } else if (treeNode.type === 'iframe') {
            if (hasElements) {
                result += '<<iframe'; // beginning
                let attributes = '';
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'attribute') {
                        attributes += astToMarkdown(subNode, additionalData, false);
                    }
                }
                result += attributes;
                result += '>>'; // end
            }

        } else if (treeNode.type === 'youtube') {
            if (hasElements) {
                if (additionalData.renderSnippetObjectsAs === RenderSnippetAs.MARKDOWN) {
                    result += '<<youtube'; // beginning
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                    result += '>>'; // end
                } else {
                    // else (by default) RenderSnippetAs.PLAIN_LINK
                    const attributes = getAttributesDict(treeNode);
                    const code = removeQuotes(attributes.hasOwnProperty('code') ? attributes.code : '');
                    result += constructYoutube(code, 0, 0, RenderSnippetAs.PLAIN_LINK);
                }
            }

        } else if (treeNode.type === 'vimeo') {
            if (hasElements) {
                if (additionalData.renderSnippetObjectsAs === RenderSnippetAs.MARKDOWN) {
                    result += '<<vimeo'; // beginning
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                    result += '>>'; // end
                } else {
                    // else (by default) RenderSnippetAs.PLAIN_LINK
                    const attributes = getAttributesDict(treeNode);
                    const code = removeQuotes(attributes.hasOwnProperty('code') ? attributes.code : '');
                    result += constructVimeo(code, 0, 0, RenderSnippetAs.PLAIN_LINK);
                }
            }

        } else if (treeNode.type === 'giphy') {
            if (hasElements) {
                if (additionalData.renderSnippetObjectsAs === RenderSnippetAs.MARKDOWN) {
                    result += '<<giphy'; // beginning
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                    result += '>>'; // end
                } else {
                    // else (by default) RenderSnippetAs.PLAIN_LINK
                    const attributes = getAttributesDict(treeNode);
                    const code = removeQuotes(attributes.hasOwnProperty('code') ? attributes.code : '');
                    result += constructGiphy(code, 0, 0, RenderSnippetAs.PLAIN_LINK);
                }
            }

        } else if (treeNode.type === 'instagram') {
            if (hasElements) {
                if (additionalData.renderSnippetObjectsAs === RenderSnippetAs.MARKDOWN) {
                    result += '<<instagram'; // beginning
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                    result += '>>'; // end
                } else {
                    // else (by default) RenderSnippetAs.PLAIN_LINK
                    const attributes = getAttributesDict(treeNode);
                    const code = removeQuotes(attributes.hasOwnProperty('code') ? attributes.code : '');
                    result += constructInstagram(code, 0, RenderSnippetAs.PLAIN_LINK);
                }
            }

        } else if (treeNode.type === 'testing') {
            if (hasElements) {
                if (additionalData.renderSnippetObjectsAs === RenderSnippetAs.MARKDOWN) {
                    let urls = '';
                    let others = '';
                    for (const subNode of treeNode.elements) {
                        if ('type' in subNode && subNode.type === 'link') {
                            urls += astToMarkdown(subNode, {...additionalData, renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK }, false);
                            urls += '\n';
                        } else {
                            others += astToMarkdown(subNode, additionalData, false);
                            others += ' ';
                        }

                    }
                    result += `<<testing ${(`${others}urls="\n${urls}"`).trim()}>>`;
                } else {
                    // else (by default) RenderSnippetAs.PLAIN_LINK
                    result += ''; // ignore rendering this item
                }
            }

        } else if (treeNode.type === 'market') {
            if (hasElements) {
                result += '<<market '; // beginning
                if (hasElements) {
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                }
                result += '>>'; // end
            }

        } else if (treeNode.type === 'strollers_chart') {
            if (hasElements) {
                result += '<<strollers_chart '; // beginning
                if (hasElements) {
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                }
                result += '>>'; // end
            }

        } else if (treeNode.type === 'pricemania') {
            if (hasElements) {
                result += '<<pricemania '; // beginning
                if (hasElements) {
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                }
                result += '>>'; // end
            }

        } else if (treeNode.type === 'reviews' || treeNode.type === 'previews') {
            if (hasElements) {
                let urls = '';
                let others = '';
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'link') {
                        urls += astToMarkdown(subNode, {...additionalData, renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK }, false);
                        urls += '\n';
                    } else {
                        others += astToMarkdown(subNode, additionalData, false);
                        others += ' ';
                    }

                }
                result += `<<previews ${(`${others}urls="\n${urls}"`).trim()}>>`;
            }

        } else if (treeNode.type === 'product_box') {
            if (hasElements) {
                let boxUrl = '';
                let others = '';
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'link') {
                        boxUrl = astToMarkdown(subNode, {...additionalData, renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK }, false);
                    } else if (subNode.type === 'attribute' && subNode.elements.some((e) => e.type === 'attribute_name' && e.text === 'url')) {
                        // Skip URL attribute - we are generating it from 'link' above
                    } else {
                        others += astToMarkdown(subNode, additionalData, false);
                        others += ' ';
                    }
                }
                result += `<<product_box ${(`${others}url="${boxUrl}"`).trim()}>>`;
            }

        } else if (treeNode.type === 'stroller_box') {
            if (hasElements) {
                let boxUrl = '';
                let others = '';
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'link') {
                        boxUrl = astToMarkdown(subNode, {...additionalData, renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK }, false);
                    } else if (subNode.type === 'attribute' && subNode.elements.some((e) => e.type === 'attribute_name' && e.text === 'url')) {
                        // Skip URL attribute - we are generating it from 'link' above
                    } else {
                        others += astToMarkdown(subNode, additionalData, false);
                        others += ' ';
                    }
                }
                result += `<<stroller_box ${(`${others}`).trim()}>>`;
            }

        } else if (treeNode.type === 'pregnancy_newsletter') {
            result += '<<pregnancy_newsletter'; // beginning
            if (hasElements) {
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData, false);
                }
            }
            result += '>>'; // end

        } else if (treeNode.type === 'pregnancy_calculator') {
            result += '<<pregnancy_calculator'; // beginning
            if (hasElements) {
                for (const subNode of treeNode.elements) {
                    result += astToMarkdown(subNode, additionalData, false);
                }
            }
            result += '>>'; // end

        } else if (treeNode.type === 'eshop') {
            if (hasElements) {
                result += '<<eshop '; // beginning
                if (hasElements) {
                    for (const subNode of treeNode.elements) {
                        result += astToMarkdown(subNode, additionalData, false);
                    }
                }
                result += '>>'; // end
            }

        } else if (treeNode.type === 'attribute') {
            if (hasElements && !ignoreAttribute) {
                let attributeName = '';
                let attributeValue = '';
                for (const subNode of treeNode.elements) {
                    if ('type' in subNode && subNode.type === 'attribute_name') {
                        attributeName = 'text' in subNode ? subNode.text : '';
                    }
                    if ('type' in subNode && subNode.type === 'attribute_value') {
                        attributeValue = 'text' in subNode ? subNode.text : '';
                    }
                }
                if (attributeName !== '' && attributeValue === BOOLEAN_ATTRIBUTE__TRUE) {
                    result += ' ' + attributeName;
                } else if (attributeName !== '' && attributeValue !== '') {
                    if (
                        attributeValue[0] !== attributeValue[attributeValue.length - 1] &&
                        !['\"', '\''].includes(attributeValue[0])
                    ) {
                      const q = attributeValue.includes('"') ? '\'' : '\"';
                      attributeValue = q + attributeValue + q;
                    }
                    result += ' ' + attributeName + '=' + attributeValue;
                } else {
                    // assert false  # missing attribute_name or attribute_value
                }
            }

        } else if (treeNode.type === 'plain_text') {
            if (hasText) {
                let text = treeNode.text;
                // escape plain text containing markdown patterns
                if (additionalData.escapeMarkdownLikePartsOfPlainText) {
                    text = text.replace(/((?:[^{]|^){3})(\*\*)((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(\/\/)((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(\[\[)((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(]])((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(\|)((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(====)((?:[^{]|$){3})/, '$1{{{====}}}$3');
                    text = text.replace(/((?:[^{=]|^){3})(===)((?:[^{=]|$){3})/, '$1{{{===}}}$3');
                    text = text.replace(/((?:[^{=]|^){3})(==)((?:[^{=]|$){3})/, '$1{{{==}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(<<pic.*?>>)((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                    text = text.replace(/((?:[^{]|^){3})(<<youtube.*?>>)((?:[^{]|$){3})/, '$1{{{$2}}}$3');
                }
                result += text;
            } else {
                // assert false  # missing text attribute
            }

        } else if (treeNode.type === 'hashtag') {
            if (hasText) {
                result += treeNode.text;
            } else {
                // assert false  # missing text attribute
            }

        } else if (treeNode.type === 'mention') {
            if (hasText) {
                result += treeNode.text;
            } else {
                // assert false  # missing text attribute
            }

        } else if (treeNode.type === 'hr') {
            result += '---';

        } else if (treeNode.type === 'br') {
            let endingOfActualFormatting = '';
            let startingOfActualFormatting = '';
            for (const mark of markdownStackEnd) {
                endingOfActualFormatting = mark + endingOfActualFormatting;
            }
            for (const mark of markdownStackStart) {
                startingOfActualFormatting = startingOfActualFormatting + mark;
            }
            result += endingOfActualFormatting + '\n' + startingOfActualFormatting;

        } else {
            // if the code reaches here, it means something is wrong - the AST contains unknown node type
            // print('---- Missing ' + treeNode['type'])
            // assert false  # unknown node type
        }

    } else {
        // if the code reaches here, it means something is wrong - the AST node has no type
        // assert false  # missing node type
    }

    return result;
};

export const isASTEmpty = (ast: AST): boolean => {
    if (ast) {
        const additionalData = {
            ...defaultFilterAdditionalData,
            renderSpecialWikiLinks: false,
            renderSnippetObjectsAs: RenderSnippetAs.PLAIN_LINK,
            renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK,
            rootDiv: false,
        };
        return !astToMarkdown(ast, additionalData).trim()?.length;
    }
    return true;
};
